<script setup lang="ts"></script>

<template>
  <div t-id="cancel-fixed-broadband-skeleton" class="cancel-fixed-broadband-skeleton">
    <telia-skeleton class="cancel-fixed-broadband-skeleton__heading" />
    <div class="cancel-fixed-broadband-skeleton__paragraph-container">
      <telia-skeleton class="cancel-fixed-broadband-skeleton__paragraph" />
      <telia-skeleton class="cancel-fixed-broadband-skeleton__paragraph" />
      <telia-skeleton class="cancel-fixed-broadband-skeleton__paragraph" />
    </div>
    <div class="cancel-fixed-broadband-skeleton__datepicker-container">
      <telia-skeleton class="cancel-fixed-broadband-skeleton__datepicker-label" />
      <telia-skeleton class="cancel-fixed-broadband-skeleton__datepicker" />
    </div>
    <div class="cancel-fixed-broadband-skeleton__price-container">
      <telia-skeleton class="cancel-fixed-broadband-skeleton__price-label" />
      <telia-skeleton class="cancel-fixed-broadband-skeleton__price" />
    </div>
    <telia-skeleton class="cancel-fixed-broadband-skeleton__VAT" />
    <div class="cancel-fixed-broadband-skeleton__button-container">
      <telia-skeleton class="cancel-fixed-broadband-skeleton__button" />
      <telia-skeleton class="cancel-fixed-broadband-skeleton__button" />
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "~@teliads/components/foundations/spacing/variables";
.cancel-fixed-broadband-skeleton {
  $self: &;
  margin-top: $telia-spacing-24;

  &__heading {
    height: 2.5rem;
    width: 80%;
    margin-bottom: $telia-spacing-16;
  }

  &__paragraph-container {
    margin-bottom: $telia-spacing-16;

    #{$self}__paragraph:nth-child(0) {
      width: 100%;
    }
    #{$self}__paragraph:nth-child(1) {
      width: 80%;
    }
    #{$self}__paragraph:nth-child(2) {
      width: 55%;
    }
  }

  &__paragraph {
    height: 1.8rem;

    & + & {
      margin-top: $telia-spacing-4;
    }
  }

  &__datepicker-container {
    margin-bottom: $telia-spacing-48;
  }

  &__datepicker-label {
    height: 1.8rem;
    width: 20%;
    margin-bottom: $telia-spacing-4;
  }

  &__datepicker {
    height: 4.4rem;
    width: 100%;
    border-radius: 0.2rem;
  }

  &__price-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: $telia-spacing-8;
  }

  &__price-label {
    height: 1.8rem;
    width: 40%;
  }

  &__price {
    height: 1.8rem;
    width: 20%;
  }

  &__VAT {
    height: 1.6rem;
    width: 100%;
    margin-bottom: $telia-spacing-24;
  }

  &__button-container {
    display: flex;
    gap: $telia-spacing-16;
  }

  &__button {
    flex: 1 1 auto;
    height: 4.8rem;
    border-radius: 10rem;
    overflow: hidden;
  }
}
</style>
