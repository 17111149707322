<template>
  <div>
    <telia-button
      t-id="manage-fixed-broadband-container__drawer-button"
      variant="primary"
      @click="openDrawer"
      >{{ t("fixedBroadband.manage.openDrawerButton") }}
    </telia-button>
    <b2x-drawer
      t-id="manage-fixed-broadband-container__drawer"
      position="right"
      :is-open="drawerOpen"
      :back-button="showBackButton"
      :heading="heading"
      @drawerClose="closeDrawer"
      @drawerBack="goToManageView"
    >
      <div>
        <div v-if="hasPendingCancellation">
          <pending-cancellation
            :cancellation-date="capabilityData.cancellationDate"
            :scope-id="scopeId"
            @abort="handleAbortCancellation"
          />
        </div>
        <div
          v-else-if="showManageFixedBroadband"
          t-id="manage-fixed-broadband-container__manage-broadband"
          class="manage-fixed-broadband-container__manage-broadband"
        >
          <manage-fixed-broadband
            :capability-data="capabilityData"
            :scope-id="scopeId"
            :fetching-cancellation-id="fetchingCancellationId"
            :fetching-option-groups="fetchingManageableOptions"
            :pending-submit="pendingSubmitChanges"
            :pending-select="pendingSelect"
            :has-changes="hasChanges"
            :has-cancellation-error="hasCancellationError"
            :has-pending-order-error="hasPendingOrderError"
            :has-submit-update-error="hasSubmitUpdateError"
            :has-select-error="hasSelectError"
            :option-groups="optionGroups"
            :basket-id="basketId"
            :price="price"
            :currentPrice="currentPrice"
            @open-cancel-broadband="initiateCancelFixedBroadband"
            @submit-changes="handleSubmitChanges"
            @cancel-changes="closeDrawer"
            @select-option="handleSelectOption"
          />
        </div>
        <div v-else>
          <cancel-fixed-broadband
            t-id="manage-fixed-broadband-container__cancel-fixed-broadband-component"
            :initiate-cancellation-pending="fetchingCancellationId"
            :initiated-cancellation="initiatedCancellation"
            :selected-cancellation-date="selectedCancellationDate"
            :submit-cancellation-pending="submitCancellationPending"
            :update-cancellation-date-pending="updateCancellationDatePending"
            :has-submit-cancellation-error="hasSubmitCancellationError"
            :has-update-cancellation-date-error="hasUpdateCancellationDateError"
            :cancellation-fee="cancellationFee"
            @submit-cancellation="handleSubmitCancelFixedBroadband"
            @abort-cancellation="handleAbortCancellation"
            @change-cancellation-date="handleChangeCancellationDate"
          />
        </div>
      </div>
    </b2x-drawer>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { translateSetup, translateMixin } from "./locale";
import {
  changeCancellationDate,
  initiateCancellation,
  submitCancellation,
} from "./services/cancel-internet-access-service";
import {
  getManageableOptions,
  selectOption,
  submitManageableOptions,
} from "./services/manageable-options-internet-access-service";
import CancelFixedBroadband from "./components/cancel-fixed-broadband.ce.vue";
import ManageFixedBroadband from "./components/manage-fixed-broadband.ce.vue";
import PendingCancellation from "./components/pending-cancellation.ce.vue";
import { addNewToast } from "@telia/b2b-message-service";
import "@telia/b2x-drawer";
import "@telia/b2x-radio-card";
import { formatDate } from "./utils/formatters";

export default defineComponent({
  name: "ManageFixedBroadbandContainer",
  components: { CancelFixedBroadband, ManageFixedBroadband, PendingCancellation },
  mixins: [translateMixin],
  props: {
    capabilityData: {
      type: Object,
      required: true,
    },
    scopeId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    drawerOpen: false,
    showManageFixedBroadband: true,
    fetchingCancellationId: false,
    fetchingManageableOptions: false,
    submitCancellationPending: false,
    updateCancellationDatePending: false,
    hasUpdateCancellationDateError: false,
    selectedCancellationDate: null,
    hasCancellationError: false,
    hasSubmitUpdateError: false,
    hasSelectError: false,
    hasPendingOrderError: false,
    hasSubmitCancellationError: false,
    manageableOptionsResponse: null,
    initiatedCancellation: null,
    pendingSubmitChanges: false,
    pendingSelect: false,
    optionGroupsChanged: new Set(),
    price: 0,
    currentPrice: 0,
    cancellationFee: null,
  }),
  created() {
    translateSetup();
  },
  computed: {
    showBackButton() {
      return !this.showManageFixedBroadband;
    },
    heading() {
      return this.showManageFixedBroadband
        ? this.t("fixedBroadband.manage.drawerHeading")
        : this.t("fixedBroadband.manage.cancelHeading");
    },
    hasChanges() {
      return this.optionGroupsChanged.size > 0;
    },
    hasPendingCancellation() {
      return !!this.capabilityData.cancellationDate;
    },
    optionGroups() {
      return this.manageableOptionsResponse?.manageableOptions;
    },
    basketId() {
      return this.manageableOptionsResponse?.basketId;
    },
  },
  methods: {
    openDrawer() {
      this.drawerOpen = true;
      this.resetDrawer();
      this.fetchManageableOptions();
    },
    closeDrawer() {
      this.drawerOpen = false;
    },
    resetDrawer() {
      this.price = 0;
      this.optionGroupsChanged = new Set();
      this.hasCancellationError = false;
      this.hasUpdateCancellationDateError = false;
      this.hasPendingOrderError = false;
      this.hasSelectError = false;
      this.hasSubmitCancellationError = false;
      this.hasSubmitUpdateError = false;
      this.selectedCancellationDate = null;
    },
    async initiateCancelFixedBroadband() {
      try {
        this.fetchingCancellationId = true;
        this.hasCancellationError = false;
        this.showManageFixedBroadband = false;
        this.initiatedCancellation = await initiateCancellation(
          this.scopeId,
          this.capabilityData.id
        );
        this.cancellationFee = this.initiatedCancellation.cancellationFee;
        this.selectedCancellationDate = this.initiatedCancellation.defaultCancellationDate;
      } catch {
        this.hasCancellationError = true;
        this.showManageFixedBroadband = true;
      } finally {
        this.fetchingCancellationId = false;
      }
    },
    async handleSubmitCancelFixedBroadband() {
      try {
        this.submitCancellationPending = true;
        this.hasSubmitCancellationError = false;
        const cancellation = await submitCancellation(
          this.scopeId,
          this.initiatedCancellation?.cancellationId
        );
        this.closeDrawer();
        addNewToast(
          `cancel-internet-access-${this.initiatedCancellation?.cancellationId}`,
          "success",
          this.t("fixedBroadband.manage.confirmation.cancellationHeading", {
            date: this.toFormattedDate(cancellation.date),
          }),
          this.t("fixedBroadband.manage.confirmation.content")
        );
        this.showManageFixedBroadband = true;
      } catch {
        this.hasSubmitCancellationError = true;
      } finally {
        this.submitCancellationPending = false;
      }
    },
    async handleChangeCancellationDate(date) {
      this.updateCancellationDatePending = true;
      this.hasUpdateCancellationDateError = false;
      try {
        const { cancellationFee } = await changeCancellationDate(
          this.scopeId,
          this.initiatedCancellation?.cancellationId,
          date
        );
        this.selectedCancellationDate = date;
        this.cancellationFee = cancellationFee;
      } catch {
        this.hasUpdateCancellationDateError = true;
      } finally {
        this.updateCancellationDatePending = false;
      }
    },
    async fetchManageableOptions() {
      try {
        this.fetchingManageableOptions = true;
        this.hasCancellationError = false;
        this.manageableOptionsResponse = await getManageableOptions(
          this.scopeId,
          this.capabilityData.id
        );
        this.currentPrice = this.manageableOptionsResponse?.currentPrice?.amount;
      } catch {
        this.hasCancellationError = true;
      } finally {
        this.fetchingManageableOptions = false;
      }
    },
    handleAbortCancellation() {
      this.showManageFixedBroadband = true;
      this.drawerOpen = false;
    },
    async handleSubmitChanges() {
      if (!this.pendingSubmitChanges) {
        this.pendingSubmitChanges = true;
        this.hasPendingOrderError = false;
        this.hasSubmitUpdateError = false;
        try {
          await submitManageableOptions(
            this.scopeId,
            this.capabilityData.id,
            this.manageableOptionsResponse.basketId
          );
          addNewToast(
            `change-internet-access-${this.manageableOptionsResponse?.basketId}`,
            "success",
            this.t("fixedBroadband.manage.confirmation.changeHeading"),
            this.t("fixedBroadband.manage.confirmation.content")
          );
          this.closeDrawer();
        } catch (ex) {
          if (ex?.body?.translationKey === "HAS_PENDING_ORDER") {
            this.hasPendingOrderError = true;
          } else {
            this.hasSubmitUpdateError = true;
          }
        } finally {
          this.pendingSubmitChanges = false;
        }
      }
    },
    async handleSelectOption(selectedOption) {
      const { optionGroupId, option } = selectedOption;
      if (!this.pendingSelect) {
        this.hasSelectError = false;
        this.pendingSelect = true;
        try {
          const result = await selectOption(this.scopeId, this.capabilityData.id, {
            id: optionGroupId,
            value: option,
            basketId: this.basketId,
          });
          this.price = result.price.amount;
          this.handleGroupChanged(
            optionGroupId,
            this.optionGroups.find((group) => group.id === optionGroupId).selectedOption.value !==
              option
          );
        } catch (error) {
          this.hasSelectError = true;
        } finally {
          this.pendingSelect = false;
        }
      }
    },
    goToManageView() {
      this.showManageFixedBroadband = true;
    },
    toFormattedDate(dateStr) {
      const date = new Date(dateStr);
      return formatDate(date);
    },
    handleGroupChanged(group, changed) {
      if (changed) {
        this.optionGroupsChanged.add(group);
      } else {
        this.optionGroupsChanged.delete(group);
      }
    },
  },
});
</script>

<style lang="scss"></style>
