export default {
  buttonLabel: "Välj datum",
  placeholder: "YYYY-MM-DD",
  selectedDateMessage: "Valt datum är",
  prevMonthLabel: "Föregående månad",
  nextMonthLabel: "Nästa månad",
  monthSelectLabel: "Månad",
  yearSelectLabel: "År",
  closeLabel: "Stäng",
  calendarHeading: "Välj ett datum",
  dayNames: ["Söndag", "Måndag", "Tisdag", "Onsdag", "Torsdag", "Fredag", "Lördag"],
  monthNames: [
    "Januari",
    "Februari",
    "Mars",
    "April",
    "Maj",
    "Juni",
    "Juli",
    "Augusti",
    "September",
    "Oktober",
    "November",
    "December",
  ],
  monthNamesShort: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "Maj",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Okt",
    "Nov",
    "Dec",
  ],
  locale: "sv-SE",
};
