import { corpManageInternetAccess } from "@telia/b2b-rest-client";

export const initiateCancellation = async (scopeId, c2BUid) => {
  return corpManageInternetAccess.CancellationControllerService.initiateCancellation(
    scopeId,
    c2BUid
  );
};

export const submitCancellation = async (scopeId, cancellationId) => {
  return corpManageInternetAccess.CancellationControllerService.submitCancellation(
    scopeId,
    cancellationId
  );
};
export const changeCancellationDate = async (scopeId, cancellationId, date) => {
  return corpManageInternetAccess.CancellationControllerService.setCancellationDate(
    scopeId,
    cancellationId,
    { date }
  );
};
