import { corpManageInternetAccess } from "@telia/b2b-rest-client";
import { currentLanguage } from "@telia/b2b-i18n";

export const getManageableOptions = async (scopeId, c2BUid) => {
  const language = currentLanguage().toUpperCase();
  return corpManageInternetAccess.ManageableOptionsControllerService.getManageableOptions(
    scopeId,
    c2BUid,
    language
  );
};

export const submitManageableOptions = async (scopeId, c2BUid, basketId) => {
  return corpManageInternetAccess.ManageableOptionsControllerService.submitManageableOptions(
    scopeId,
    c2BUid,
    basketId
  );
};

export const selectOption = async (scopeId, c2BUid, request) => {
  return corpManageInternetAccess.ManageableOptionsControllerService.selectOption(
    scopeId,
    c2BUid,
    request
  );
};
