<template>
  <div class="price-details">
    <template v-if="loadingPrice">
      <telia-row>
        <telia-col width="6">
          <telia-skeleton class="price-details__price-description-skeleton" />
        </telia-col>
        <telia-col width="6">
          <telia-skeleton class="price-details__price-amount-skeleton" />
        </telia-col>
      </telia-row>
      <telia-row>
        <telia-col width="6">
          <telia-skeleton class="price-details__current-price-description-skeleton" />
        </telia-col>
        <telia-col width="6">
          <telia-skeleton class="price-details__current-price-amount-skeleton" />
        </telia-col>
      </telia-row>
      <telia-skeleton class="price-details__excluding-container-skeleton" />
    </template>
    <template v-else>
      <telia-row>
        <telia-col width="6">
          <telia-p class="price-details__price-description"
            >{{ t("fixedBroadband.manage.priceDetails.priceDescription") }}
          </telia-p>
        </telia-col>
        <telia-col class="price-details__price-amount" width="6">
          <telia-p t-id="price-details__price-amount">{{ price }} kr</telia-p>
        </telia-col>
      </telia-row>
      <telia-row>
        <telia-col width="6">
          <telia-p variant="additional-100" class="price-details__current-price-description"
            >{{ t("fixedBroadband.manage.priceDetails.currentPriceDescription") }}
          </telia-p>
        </telia-col>
        <telia-col class="price-details__current-price-amount" width="6">
          <telia-p variant="additional-100" t-id="price-details__current-price-amount"
            >{{ currentPrice }} kr</telia-p
          >
        </telia-col>
      </telia-row>
      <telia-p class="price-details__excluding-container" variant="additional-100">
        <span class="price-details__excluding"
          >{{ t("fixedBroadband.manage.priceDetails.vatExcluded") }}
        </span>
      </telia-p>
    </template>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { translateMixin, translateSetup } from "../locale";

export default defineComponent({
  name: "PriceDetails",
  mixins: [translateMixin],
  props: {
    price: {
      type: Number,
      required: true,
    },
    currentPrice: {
      type: Number,
      required: true,
    },
    loadingPrice: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    translateSetup();
  },
});
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/typography/variables";
@import "~@teliads/components/foundations/spacing/variables";

.price-details {
  padding-bottom: $telia-spacing-32;

  &__price-description {
    font-weight: $telia-typography-weight-medium;

    &-skeleton {
      height: 2rem;
      width: 16rem;
    }
  }

  &__price-amount {
    text-align: right;
    font-weight: $telia-typography-weight-medium;

    &-skeleton {
      height: 2rem;
      width: 5rem;
      float: right;
    }
  }

  &__current-price-description {
    font-weight: $telia-typography-weight-medium;
    color: $telia-typography-color-medium;

    &-skeleton {
      margin-top: $telia-spacing-4;
      height: 1.5rem;
      width: 12rem;
    }
  }

  &__current-price-amount {
    text-align: right;
    font-weight: $telia-typography-weight-medium;
    color: $telia-typography-color-medium;

    &-skeleton {
      margin-top: $telia-spacing-4;
      height: 1.5rem;
      width: 4rem;
      float: right;
    }
  }

  &__excluding-container {
    margin-top: $telia-spacing-8;

    &-skeleton {
      margin-top: 0.8rem;
      height: 1.7rem;
      width: 20rem;
    }
  }
}
</style>
