import B2bManageFixedBroadband from "./b2b-manage-fixed-broadband.ce.vue";
import CancelFixedBroadbandCe from "./components/cancel-fixed-broadband.ce.vue";
import CancelFixedBroadbandSkeletonCe from "./components/cancel-fixed-broadband-skeleton.ce.vue";
import ManageFixedBroadbandCe from "./components/manage-fixed-broadband.ce.vue";
import OptionGroupCe from "./components/option-group.ce.vue";
import PendingCancellationCe from "./components/pending-cancellation.ce.vue";
import StatusNotificationCe from "./components/status-notification.ce.vue";
import CancellationPriceCe from "./components/cancellation-fee.ce.vue";
import PriceDetailsCe from "./components/price-details.ce.vue";

const styles = mergeStylesOfComponents(
  B2bManageFixedBroadband,
  CancelFixedBroadbandCe,
  CancelFixedBroadbandSkeletonCe,
  ManageFixedBroadbandCe,
  PriceDetailsCe,
  OptionGroupCe,
  PendingCancellationCe,
  StatusNotificationCe,
  CancellationPriceCe
);

B2bManageFixedBroadband.styles = styles;

export default B2bManageFixedBroadband;

function mergeStylesOfComponents(...components): string[] {
  return components.flatMap(({ styles }) => styles);
}
