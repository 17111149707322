<template>
  <div t-id="pending-cancellation">
    <telia-heading tag="h3" class="pending-cancellation__pending-cancellation-heading">{{
      t("fixedBroadband.manage.pendingCancellation.heading")
    }}</telia-heading>
    <telia-p t-id="pending-cancellation__body" class="pending-cancellation__body">
      {{
        t("fixedBroadband.manage.pendingCancellation.content", {
          cancellationDate: formatCancellationDate(cancellationDate),
        })
      }}
    </telia-p>
    <div class="pending-cancellation__pending-cancellation-buttons">
      <telia-button
        class="pending-cancellation__pending-cancellation-button"
        t-id="pending-cancellation__abort-button"
        type="button"
        variant="secondary"
        full-width="true"
        @click="handleAbortPendingCancellation"
        >{{ t("fixedBroadband.manage.cancelButton") }}
      </telia-button>
      <telia-cta-link
        class="pending-cancellation__pending-cancellation-button"
        t-id="pending-cancellation__go-to-support"
        :href="supportContactUrl"
        full-width="true"
        variant="primary"
      >
        {{ t("fixedBroadband.manage.pendingCancellation.toSupportButtonLabel") }}
      </telia-cta-link>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { currentLanguage } from "@telia/b2b-i18n";
import { translateMixin, translateSetup } from "../locale";

export default defineComponent({
  name: "PendingCancellation",
  mixins: [translateMixin],
  props: {
    cancellationDate: { type: String, required: true },
    scopeId: { type: String, required: true },
  },
  created() {
    translateSetup();
  },
  computed: {
    supportContactUrl() {
      return `/foretag/mybusiness/${this.scopeId}/support/kontakt`;
    },
  },
  methods: {
    formatCancellationDate(dateString) {
      const date = new Date(dateString || "");
      return date.toString() !== "Invalid Date"
        ? date.toLocaleDateString(currentLanguage(), {
            month: "long",
            day: "numeric",
            year: "numeric",
          })
        : undefined;
    },
    handleAbortPendingCancellation() {
      this.$emit("abort");
    },
  },
});
</script>

<style lang="scss">
@import "~@teliads/components/foundations/spacing/variables";

.pending-cancellation {
  &__pending-cancellation-heading {
    margin-bottom: $telia-spacing-16;
  }

  &__body {
    margin-bottom: $telia-spacing-32;
  }

  &__pending-cancellation-buttons {
    display: flex;
    gap: $telia-spacing-16;
    margin-bottom: $telia-spacing-16;
  }

  &__pending-cancellation-button {
    flex-grow: 1;
    width: 100%;
  }
}
</style>
