<template>
  <section
    v-if="hasMoreThanOneOption"
    :t-id="`option-group__${optionGroup.id}`"
    class="option-group"
  >
    <telia-heading tag="h3" variant="subsection-100" class="option-group__heading">
      {{ optionGroup.header }}
    </telia-heading>
    <div class="option-group__options">
      <div v-if="optionGroup.isHorizontal">
        <PurpurChipGroup
          :data-testid="`option-group-${optionGroup.id}__options`"
          type="choice"
          :value="selectedOption"
          :onValueChange="optionSelected"
        >
          <PurpurChipGroupItem
            v-for="option in optionGroup.options"
            :data-testid="`option-group-option__${option.value}`"
            :value="option.value"
            :disabled="disableButtons"
            >{{ option.label }}
          </PurpurChipGroupItem>
        </PurpurChipGroup>
      </div>
      <div v-else>
        <PurpurRadioCardGroup
          :value="selectedOption"
          :onValueChange="optionSelected"
          :data-testid="`option-group-${optionGroup.id}__options`"
          :disabled="disableButtons"
        >
          <PurpurRadioCardGroupItem
            v-for="option in optionGroup.options"
            :key="option.value"
            :id="`option-group-option__${option.value}`"
            :data-testid="`option-group-option__${option.value}`"
            :title="option.label"
            :body="richTextDocumentToString(option.longDescription)"
            :value="option.value"
            :disabled="disableButtons"
          />
        </PurpurRadioCardGroup>
      </div>
    </div>
    <telia-p class="option-group__disclaimer">{{ optionGroup.disclaimer }}</telia-p>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import { toString } from "../helpers/rich-text-renderer";
import { translateMixin, translateSetup } from "../locale";
import { applyPureReactInVue } from "veaury";
import { ChipGroup, RadioCardGroup, RadioCardItem } from "@purpurds/purpur";

export default defineComponent({
  name: "OptionGroup",
  mixins: [translateMixin],
  components: {
    PurpurChipGroup: applyPureReactInVue(ChipGroup),
    PurpurChipGroupItem: applyPureReactInVue(ChipGroup.Item),
    PurpurRadioCardGroup: applyPureReactInVue(RadioCardGroup),
    PurpurRadioCardGroupItem: applyPureReactInVue(RadioCardItem),
  },
  props: {
    optionGroup: {
      type: Object,
      required: true,
    },
    disableButtons: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    translateSetup();
    this.selectedOption = this.optionGroup.selectedOption?.value;
  },
  data: () => ({
    selectedOption: null,
  }),
  computed: {
    hasMoreThanOneOption() {
      return this.optionGroup.options.length > 1;
    },
  },
  methods: {
    richTextDocumentToString(document) {
      const parsedDocument = JSON.parse(document);
      return toString(parsedDocument);
    },
    optionSelected(option) {
      if (this.disableButtons === false) {
        this.selectedOption = option;
        this.$emit("selected-option", option);
      }
    },
  },
});
</script>

<style lang="scss">
@import "@purpurds/purpur/styles";
@import "@purpurds/tokens/index.css";

@import "~@teliads/components/foundations/typography/variables";
@import "~@teliads/components/foundations/spacing/variables";
@import "~@teliads/components/foundations/colors/tokens";

.option-group {
  margin-bottom: $telia-spacing-32;

  &__heading,
  &__description,
  &__options {
    margin-bottom: $telia-spacing-16;
  }

  &__disclaimer {
    color: $telia-typography-color-medium;
    margin-bottom: $telia-spacing-32;
  }
}
</style>
