<template>
  <telia-notification
    variant="inline"
    :status="status"
    :heading-tag="headingTag"
    :button-text="buttonKey ? t(buttonKey) : null"
    @vocaClose="$emit('close')"
    :html-aria-live="ariaLive"
    :button-aria-label="status"
  >
    <span slot="heading">
      <telia-visually-hidden v-if="status === 'error'">{{
        t("common.errorNotification")
      }}</telia-visually-hidden>
      <telia-visually-hidden v-else-if="status === 'success'">{{
        t("common.successNotification")
      }}</telia-visually-hidden>
      <telia-visually-hidden v-else-if="status === 'information'">{{
        t("common.informationNotification")
      }}</telia-visually-hidden>
      <telia-visually-hidden v-else-if="status === 'warning'">{{
        t("common.warningNotification")
      }}</telia-visually-hidden>
      <span class="status-notification__header">{{ t(headingKey) }}</span>
    </span>
    <telia-p v-if="link" slot="content" disable-hyphenation>
      <slot name="link" />
    </telia-p>
    <telia-p v-else slot="content" disable-hyphenation>{{ t(contentKey) }}</telia-p>
  </telia-notification>
</template>

<script>
import { defineComponent } from "vue";
import { translateMixin, translateSetup } from "../locale";

export default defineComponent({
  name: "StatusNotification",
  mixins: [translateMixin],
  props: {
    headingTag: {
      type: String,
      required: true,
    },
    headingKey: {
      type: String,
      required: true,
    },
    contentKey: {
      type: String,
      required: true,
    },
    buttonKey: {
      type: String,
    },
    status: {
      type: String,
      required: true,
    },
    ariaLive: {
      type: String,
      default: "off",
    },
    link: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    translateSetup();
  },
});
</script>
<style lang="scss" scoped>
.status-notification {
  &__header {
    font-weight: bold;
  }
}
</style>
