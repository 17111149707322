<template>
  <div>
    <div v-if="capabilityData.modifyInternetAccessPossible">
      <div v-if="fetchingOptionGroups">
        <telia-skeleton class="manage-fixed-broadband__skeleton-information" />
        <telia-skeleton class="manage-fixed-broadband__skeleton-heading" />
        <telia-skeleton class="manage-fixed-broadband__skeleton-description" />
        <div class="manage-fixed-broadband__skeleton-options">
          <telia-skeleton class="manage-fixed-broadband__skeleton-option" />
          <telia-skeleton class="manage-fixed-broadband__skeleton-option" />
          <telia-skeleton class="manage-fixed-broadband__skeleton-option" />
        </div>
        <telia-skeleton class="manage-fixed-broadband__skeleton-disclaimer" />
      </div>
      <div v-else>
        <telia-p class="manage-fixed-broadband__commitment-time-information">
          {{ t("fixedBroadband.manage.commitmentTimeInformation") }}</telia-p
        >
        <option-group
          v-for="group in optionGroups"
          :key="group.id"
          :option-group="group"
          :scope-id="scopeId"
          :basket-id="basketId"
          :disableButtons="optionsDisabled"
          @selected-option="handleSelectOption(group.id, $event)"
        />
        <price-details
          v-if="showPrice"
          :currentPrice="currentPrice"
          :price="price"
          :loadingPrice="pendingSelect"
        />
      </div>
      <div class="manage-fixed-broadband__buttons">
        <telia-button
          class="manage-fixed-broadband__cancel-change-button"
          t-id="manage-fixed-broadband__cancel-change-button"
          type="button"
          variant="secondary"
          full-width
          :disabled="pendingSubmit"
          @click="handleCancelChanges"
          >{{ t("fixedBroadband.manage.cancelButton") }}
        </telia-button>
        <b2x-loading-button
          class="manage-fixed-broadband__submit-change-button"
          t-id="manage-fixed-broadband__submit-change-button"
          full-width
          variant="primary"
          :is-loading="pendingSubmit"
          :disabled="submitDisabled"
          :loading-label="t('fixedBroadband.manage.savingChangesButton')"
          @click="handleSubmitChanges"
        >
          {{ t("fixedBroadband.manage.saveChangesButton") }}
        </b2x-loading-button>
      </div>
    </div>
    <div
      v-if="hasUpdateBroadbandError || hasPendingOrderError"
      class="manage-fixed-broadband__error-notification"
    >
      <status-notification
        v-if="hasUpdateBroadbandError"
        t-id="manage-fixed-broadband__error"
        heading-tag="h3"
        heading-key="fixedBroadband.manage.error.heading"
        content-key="fixedBroadband.manage.error.content"
        status="error"
      />
      <status-notification
        v-if="hasPendingOrderError"
        t-id="manage-fixed-broadband__pending-error"
        heading-tag="h3"
        heading-key="fixedBroadband.manage.pendingOrderError.heading"
        content-key="fixedBroadband.manage.pendingOrderError.content"
        status="error"
      />
    </div>
    <telia-button
      v-if="capabilityData.cancellationPossible"
      class="manage-fixed-broadband__initiate-cancellation-button"
      t-id="manage-fixed-broadband__initiate-cancellation-button"
      type="button"
      variant="tertiary-purple"
      :disabled="fetchingCancellationId || pendingSubmit"
      @click="handleOpenCancelBroadband"
      >{{ t("fixedBroadband.manage.cancelBroadbandButton") }}
    </telia-button>
    <status-notification
      v-if="hasCancellationError"
      t-id="manage-fixed-broadband__initiate-cancellation-error"
      heading-tag="h3"
      heading-key="fixedBroadband.manage.error.heading"
      content-key="fixedBroadband.manage.error.content"
      status="error"
    />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { translateMixin, translateSetup } from "../locale";
import StatusNotification from "./status-notification.ce.vue";
import OptionGroup from "./option-group.ce.vue";
import PriceDetails from "./price-details.ce";

export default defineComponent({
  name: "ManageFixedBroadband",
  mixins: [translateMixin],
  components: { PriceDetails, OptionGroup, StatusNotification },
  props: {
    capabilityData: {
      type: Object,
      required: true,
    },
    scopeId: {
      type: String,
      required: true,
    },
    basketId: {
      type: [String, undefined],
    },
    fetchingCancellationId: { type: Boolean, required: true },
    fetchingOptionGroups: { type: Boolean, required: true },
    pendingSelect: { type: Boolean, default: false },
    pendingSubmit: { type: Boolean, default: false },
    hasChanges: { type: Boolean, default: false },
    hasCancellationError: { type: Boolean, default: false },
    hasPendingOrderError: { type: Boolean, default: false },
    hasSelectError: { type: Boolean, default: false },
    hasSubmitUpdateError: { type: Boolean, default: false },
    optionGroups: { type: Array, default: () => [] },
    price: { type: Number, default: 0 },
    currentPrice: { type: Number, default: 0 },
  },
  created() {
    translateSetup();
  },
  computed: {
    submitDisabled() {
      return !this.hasChanges || this.fetchingOptionGroups || this.pendingSelect;
    },
    optionsDisabled() {
      return this.pendingSubmit || this.pendingSelect;
    },
    showPrice() {
      return this.hasChanges | this.pendingSelect;
    },
    hasUpdateBroadbandError() {
      return this.hasSelectError | this.hasSubmitUpdateError;
    },
  },
  methods: {
    handleOpenCancelBroadband() {
      this.$emit("open-cancel-broadband");
    },
    handleSelectOption(optionGroupId, option) {
      this.$emit("select-option", { optionGroupId, option });
    },
    handleCancelChanges() {
      this.$emit("cancel-changes");
    },
    handleSubmitChanges() {
      this.$emit("submit-changes");
    },
  },
});
</script>

<style lang="scss">
@import "~@teliads/components/foundations/borders/variables";
@import "~@teliads/components/foundations/spacing/variables";

.manage-fixed-broadband {
  &__skeleton {
    &-information {
      height: $telia-spacing-24;
      margin-bottom: $telia-spacing-16;
    }
    &-heading {
      height: $telia-spacing-48;
      margin-bottom: $telia-spacing-16;
    }

    &-description {
      height: $telia-spacing-48;
      margin-bottom: $telia-spacing-16;
    }

    &-options {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: $telia-spacing-16;
      margin-bottom: $telia-spacing-16;
    }

    &-option {
      height: 5.6rem;
    }

    &-disclaimer {
      height: 2rem;
      margin-bottom: $telia-spacing-16;
    }
  }

  &__error-notification {
    margin-bottom: $telia-spacing-16;
  }

  &__buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $telia-spacing-8;
    margin-bottom: $telia-spacing-16;
  }

  &__initiate-cancellation-button {
    display: flex;
    justify-content: center;
    margin-bottom: $telia-spacing-16;
  }

  &__commitment-time-information {
    margin-bottom: $telia-spacing-16;
  }
}
</style>
