<script setup lang="ts">
import { translateMixin, translateSetup } from "../locale";

translateSetup([]);
const t = translateMixin.methods.t;

interface Props {
  fee: string;
  updateCancellationDatePending: boolean;
}

defineProps<Props>();
</script>

<template>
  <div class="cancellation-fee">
    <div class="cancellation-fee__fee-container">
      <telia-p class="cancellation-fee__fee-label"
        ><span class="cancellation-fee__font-medium">{{
          t("fixedBroadband.manage.cancellationFeeLabel")
        }}</span></telia-p
      >
      <telia-p
        v-if="!updateCancellationDatePending"
        t-id="cancellation-fee__fee"
        class="cancellation-fee__fee"
        ><span class="cancellation-fee__font-medium">{{
          t("fixedBroadband.manage.cancellationFeePrice", { fee })
        }}</span></telia-p
      >
      <telia-skeleton v-else t-id="cancellation-fee__skeleton" class="cancellation-fee__skeleton" />
    </div>
    <telia-p variant="additional-100"
      ><span class="cancellation-fee__font-medium cancellation-fee__lighter-color">{{
        t("fixedBroadband.manage.cancellationFeeVATLabel")
      }}</span></telia-p
    >
  </div>
</template>

<style scoped lang="scss">
@import "~@teliads/components/foundations/spacing/variables";
@import "~@teliads/components/foundations/colors/variables";
@import "~@teliads/components/foundations/typography/tokens";

.cancellation-fee {
  margin-bottom: $telia-spacing-24;

  &__fee-container {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: $telia-spacing-8;
  }

  &__fee-label {
    flex: 1 1 auto;
  }

  &__fee {
    flex: 0 0 auto;
  }

  &__lighter-color {
    color: $telia-transparent-black-600;
  }

  &__font-medium {
    font-weight: $telia-typography-weight-medium;
  }

  &__skeleton {
    flex: 1 1 auto;
  }
}
</style>
