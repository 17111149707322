<template>
  <div>
    <cancel-fixed-broadband-skeleton v-if="initiateCancellationPending" />
    <div v-else>
      <form t-id="cancel-fixed-broadband__form" @submit.prevent="submitCancelFixedBroadband">
        <telia-badge
          v-if="initiatedCancellation.commitmentEndDate"
          t-id="cancel-fixed-broadband__commitment-badge"
          variant="information"
          class="cancel-fixed-broadband__commitment-badge"
          >{{
            t("fixedBroadband.manage.commitmentInformationBadge", {
              date: toFormattedDate(initiatedCancellation.commitmentEndDate),
            })
          }}
        </telia-badge>
        <telia-heading
          class="cancel-fixed-broadband__margin-bottom-large"
          tag="h3"
          variant="title-100"
          >{{ t("fixedBroadband.manage.chooseTerminationDateTitle") }}
        </telia-heading>
        <telia-p class="cancel-fixed-broadband__margin-bottom-large"
          ><span class="cancel-fixed-broadband__bold">{{
            t("fixedBroadband.manage.noticePeriodTextPart1")
          }}</span
          >{{ t("fixedBroadband.manage.noticePeriodTextPart2") }}
        </telia-p>
        <telia-date-picker
          v-if="initiatedCancellation && datePickerReloadToggle"
          t-id="cancel-fixed-broadband__date-picker"
          class="cancel-fixed-broadband__date-picker"
          :label="t('fixedBroadband.manage.datePickerLabel')"
          :additional="t('fixedBroadband.manage.datePickerAdditional')"
          name="cancellationDate"
          :min="initiatedCancellation.earliestCancellationDate"
          :max="initiatedCancellation.latestCancellationDate"
          :value="selectedCancellationDate"
          :localization.prop="datePickerTranslations"
          :disabled="actionPending"
          :invalid-error-message="t('fixedBroadband.manage.datePickerInvalidDate')"
          :required-error-message="t('fixedBroadband.manage.datePickerDateRequired')"
          required
          @vocaChange="handleSetCancellationDate($event.detail.value)"
        />
        <status-notification
          v-if="hasUpdateCancellationDateError"
          t-id="cancel-fixed-broadband__update-cancellation-date-error"
          class="cancel-fixed-broadband__update-cancellation-date-error"
          heading-tag="h3"
          heading-key="fixedBroadband.manage.updateCancellationDateError.heading"
          content-key="fixedBroadband.manage.updateCancellationDateError.content"
          status="error"
        ></status-notification>
        <cancellation-fee
          v-if="hasCancellationFee"
          t-id="cancel-fixed-broadband__cancellation-fee"
          :fee="cancellationFee"
          :update-cancellation-date-pending="updateCancellationDatePending"
        ></cancellation-fee>
        <div class="cancel-fixed-broadband__cancel-broadband-buttons">
          <telia-button
            class="cancel-fixed-broadband__cancel-broadband-button"
            t-id="cancel-fixed-broadband__abort-cancellation-button"
            type="button"
            variant="secondary"
            full-width="true"
            :disabled="actionPending"
            @click="handleAbortCancellation"
            >{{ t("fixedBroadband.manage.cancelButton") }}
          </telia-button>
          <b2x-loading-button
            class="cancel-fixed-broadband__cancel-broadband-button"
            t-id="cancel-fixed-broadband__cancel-broadband-button"
            type="submit"
            variant="destructive"
            full-width="true"
            :is-loading="submitCancellationPending"
            :disabled="actionPending || dateValidationError"
            >{{ t("fixedBroadband.manage.cancelBroadbandButton") }}
          </b2x-loading-button>
        </div>
      </form>
      <status-notification
        v-if="hasSubmitCancellationError"
        t-id="cancel-fixed-broadband__submit-cancellation-error"
        class="cancel-fixed-broadband__submit-cancellation-error"
        heading-tag="h3"
        heading-key="fixedBroadband.manage.submitCancellationError.heading"
        content-key="fixedBroadband.manage.submitCancellationError.content"
        status="error"
      ></status-notification>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { currentLanguage } from "@telia/b2b-i18n";
import { translateMixin, translateSetup } from "../locale";
import { formatDate } from "../utils/formatters";
import datePickerTranslationsSV from "../locale/sv/datePickerTranslations";
import datePickerTranslationsEN from "../locale/en/datePickerTranslations";
import CancelFixedBroadbandSkeleton from "./cancel-fixed-broadband-skeleton.ce.vue";
import StatusNotification from "./status-notification.ce.vue";
import CancellationFee from "./cancellation-fee.ce.vue";

export default defineComponent({
  name: "cancel-fixed-broadband",
  components: { CancellationFee, StatusNotification, CancelFixedBroadbandSkeleton },
  mixins: [translateMixin],
  props: {
    initiateCancellationPending: { type: Boolean, default: false },
    initiatedCancellation: { type: Object },
    selectedCancellationDate: { type: String },
    submitCancellationPending: { type: Boolean, default: false },
    hasSubmitCancellationError: { type: Boolean, default: false },
    updateCancellationDatePending: { type: Boolean, default: false },
    hasUpdateCancellationDateError: { type: Boolean, default: false },
    cancellationFee: { type: String },
  },
  data() {
    return {
      datePickerReloadToggle: true,
      dateValidationError: false,
    };
  },
  created() {
    translateSetup();
  },
  computed: {
    datePickerTranslations() {
      return currentLanguage() === "sv" ? datePickerTranslationsSV : datePickerTranslationsEN;
    },
    actionPending() {
      return this.submitCancellationPending || this.updateCancellationDatePending;
    },
    hasCancellationFee() {
      return this.cancellationFee !== null;
    },
  },
  methods: {
    handleAbortCancellation() {
      this.$emit("abort-cancellation");
    },
    toFormattedDate(dateStr) {
      const date = new Date(dateStr);
      return formatDate(date);
    },
    submitCancelFixedBroadband() {
      this.$emit("submit-cancellation");
    },
    handleSetCancellationDate(date) {
      if (date && this.dateInRange(new Date(date))) {
        this.$emit("change-cancellation-date", date);
        this.dateValidationError = false;
      } else {
        this.dateValidationError = true;
      }
    },
    dateInRange(date) {
      const earliestCancellationDate = new Date(
        this.initiatedCancellation.earliestCancellationDate
      );
      const latestCancellationDate = new Date(this.initiatedCancellation.latestCancellationDate);
      return earliestCancellationDate <= date && date <= latestCancellationDate;
    },
  },
  watch: {
    hasUpdateCancellationDateError: {
      handler: async function reloadHandler(val, oldVal) {
        if (oldVal === false) {
          this.datePickerReloadToggle = false;
          await this.$nextTick();
          this.datePickerReloadToggle = true;
        }
      },
    },
  },
});
</script>

<style lang="scss">
@import "~@teliads/components/foundations/spacing/variables";
@import "~@teliads/components/foundations/typography/variables";
@import "~@teliads/components/foundations/breakpoints/variables.scss";

.cancel-fixed-broadband {
  &__cancel-broadband-buttons {
    display: flex;
    gap: $telia-spacing-16;
    margin-bottom: $telia-spacing-16;
    @media (max-width: $telia-breakpoint-medium) {
      flex-direction: column-reverse;
    }
  }

  &__cancel-broadband-button {
    flex-grow: 1;
    width: 100%;
  }

  &__bold {
    font-weight: $telia-typography-weight-bold;
  }

  &__margin-bottom {
    margin-bottom: $telia-spacing-8;
  }

  &__margin-bottom-large {
    margin-bottom: $telia-spacing-16;
  }

  &__update-cancellation-date-error {
    display: block;
    margin-bottom: $telia-spacing-32;
  }

  &__date-picker {
    display: block;
    margin-bottom: $telia-spacing-48;
  }

  &__submit-cancellation-error {
    display: block;
    margin-top: $telia-spacing-48;
  }

  &__commitment-badge {
    display: block;
    margin-top: calc($telia-spacing-8 * -1);
    margin-bottom: $telia-spacing-48;
  }
}
</style>
